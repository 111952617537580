import { useQueryClient } from "@tanstack/react-query";
import { defaultDimensionsMap } from "@ternary/api-lib/analytics/constants";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";
import { AnalyticsSchema } from "../api/analytics/AnalyticsApiClient";
import useAuthenticatedUser from "./useAuthenticatedUser";

/**
 * Custom hook that derives all available dimensions by combining default dimensions
 * with custom dimensions for a given DataSource.
 * @param {DataSource} dataSource
 */

export default function useAvailableDimensionsByDataSource(
  dataSource: DataSource
): string[] {
  const authenticatedUser = useAuthenticatedUser();
  const queryClient = useQueryClient();

  // Load focus dimensions from analytics metadata.
  if (dataSource === DataSource.FOCUS_BILLING) {
    const metadataArray = queryClient.getQueryData<AnalyticsSchema[]>([
      "analyticsMetadata",
      authenticatedUser.tenant.id,
    ]);

    if (!metadataArray) return [];

    const metadata = metadataArray.find(
      (metadata) => metadata.schemaName === DataSource.FOCUS_BILLING
    );

    if (!metadata) return [];

    return metadata.dimensions.map((dimension) => dimension.displayName).sort();
  }

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  const defaultDimensions: string[] = defaultDimensionsMap[dataSource];

  if (!labelMaps) return defaultDimensions;

  if (!labelMaps[dataSource]) return defaultDimensions;

  const labelsKeyedByDimension = labelMaps[dataSource];

  const customDimensions = Object.keys(labelsKeyedByDimension);

  return defaultDimensions
    .concat(customDimensions)
    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
}

export function useAvailableDimensionsByDataSourceWithProvider(
  dataSource: DataSource
): {
  name: string;
  providerName?: string;
}[] {
  const authenticatedUser = useAuthenticatedUser();
  const queryClient = useQueryClient();

  // Load focus dimensions from analytics metadata.
  if (dataSource === DataSource.FOCUS_BILLING) {
    const metadataArray = queryClient.getQueryData<AnalyticsSchema[]>([
      "analyticsMetadata",
      authenticatedUser.tenant.id,
    ]);

    if (!metadataArray) return [];

    const metadata = metadataArray.find(
      (metadata) => metadata.schemaName === DataSource.FOCUS_BILLING
    );

    if (!metadata) return [];

    return metadata.dimensions
      .map((dimension) => ({
        name: dimension.displayName,
        providerName: dimension.providerName,
      }))
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  }

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  const defaultDimensions: string[] = defaultDimensionsMap[dataSource] || [];

  if (!labelMaps) return defaultDimensions.map((name) => ({ name }));

  if (!labelMaps[dataSource])
    return defaultDimensions.map((name) => ({ name }));

  let dataSourceKey = dataSource;

  // DETAILED_BILLING and BILLING data sources share the same labels
  if (dataSource === DataSource.DETAILED_BILLING) {
    dataSourceKey = DataSource.BILLING;
  }

  const labelsKeyedByDimension = labelMaps[dataSourceKey];

  const customDimensions = Object.keys(labelsKeyedByDimension);

  return defaultDimensions
    .concat(customDimensions)
    .map((name) => ({ name }))
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
}
