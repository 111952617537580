import React from "react";
import { LegendProps } from "recharts";
import ChartDataManager from "./ChartDataManager";
import LegendSimple from "./LegendSimple";

type Props = {
  align?: "center" | "left";
  dataManager: ChartDataManager;
  hoveredKey?: string | string[];
  isPieChart?: boolean;
  isServer?: boolean;
  readableKeys?: { [key: string]: string };
  onHoverKey?: (key?: string) => void;
  onInteraction: (interaction: LegendSimple.Interaction) => void;
} & LegendProps;

export default function Legend(props: Props) {
  if (!props.payload) return null;

  return <LegendSimple {...props} />;
}
