import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  addBudgetButtonLabel: "Add New Budget",
  budgetFormTitleCopy: "Create Budget Copy",
  budgetFormTitleCreate: "Create New Budget",
  budgetFormTitleEdit: "Edit Existing Budget",
  budgetsCurrentDailyGraphTitle: "Current Month",
  budgetsCurrentDailyGraphTooltip: "See your current month spend and the forecast for the remainder of the month.",
  budgetsFormAlertsPlaceholder: "No alerts configured yet",
  budgetsFormAlertsTableHeadingHighlighted: "Reference Version",
  budgetsFormAlertsTableHeadingLatestAmount: "Latest Amount",
  budgetsFormAlertsTableHeadingPercentage: "Percentage",
  budgetsFormAlertsTableHeadingSpendBasis: "Spend Basis",
  budgetsFormAlertsTableHeadingThresholdWarningType: "Alert Type",
  budgetsFormButtonAddMonth: "Add Month",
  budgetsFormButtonAddNewAlert: "Add New Alert",
  budgetsFormButtonAddNewScope: "Add Scope",
  budgetsFormButtonGenerate: "Generate Months",
  budgetsFormCreditsToggleLabel: "Include Credits",
  budgetsFormInputLabelActualThreshold: "Actual",
  budgetsFormInputLabelAmount: "Amount (USD)",
  budgetsFormInputLabelName: "Budget Name",
  budgetsFormInputLabelProjectedThreshold: "Projected",
  budgetsFormInputLabelScopeKey: "Key",
  budgetsFormInputLabelScopeValue: "Value",
  budgetsFormLabelAutofillAmount: "Autofill Amount",
  budgetsFormLabelAutofillHighlighted: "Reference Version", 
  budgetsFormLabelCost: "cost",
  budgetsFormLabelMeasure: "Measure",
  budgetsFormLabelMonthCount: "Month Count",
  budgetsFormLabelNetCost: "netCost",
  budgetsFormLabelStartingMonth: "Starting Month",
  budgetsFormLabelStartingYear: "Starting Year",
  budgetsFormRecurringSwitchTooltip: "Select this if your budget should have the same amount every month. If you want to configure different amounts for every month, do not select this option.",
  budgetsFormRecurringSwitchWarning: "Converting your Variable-Amount Budget to a Recurring Budget will permanantly replace the current month and remove all future months that you configured. Previous months will be preserved. Proceed with caution.",
  budgetsFormRecurringToggleLabel: "Recurring Amount",
  budgetsFormSearch: "Search",
  budgetsFormSectionLabelAlerts: "Alerts:",
  budgetsFormSectionLabelVariableMonthAmounts: "Variable Monthly Amounts:",
  budgetsFormSpendBasisActual: "Actual Spend",
  budgetsFormSpendBasisofMonthlyBudget: "of Monthly Budget",
  budgetsFormSpendBasisProjected: "Projected Spend",
  budgetsFormStarterInstructions: "Enter the general parameters for your variable-amount budge to get started, and then click the button below to proceed to month-by-month configuration.",
  budgetsFormStarterTitle: "Configure Your Variable Monthly Amounts",
  budgetsFormSubscribers: "Subscribers",
  budgetsFormSubscribersAddNonAccount: "Subscribe non-Ternary user",
  budgetsFormSubscribersAll: "All users",
  budgetsFormSubscribersEdit: "Edit Subscribers",
  budgetsFormSubscribersEmailPlaceholder: "Email Address",
  budgetsFormSubscribersIsInternalWarning: "Address belongs to User Account",
  budgetsFormSubscribersListAccount: "Account Subscribers",
  budgetsFormSubscribersListNonAccount: "Non-Account Subscribers",
  budgetsFormSubscribersNone: "None",
  budgetsFormSubscribersUpdate: "Update Subscribers",
  budgetsFormSubscribersUserAdded: "%user% added to Account Subscribers list",
  budgetsFormSubscribersUserNotAdded: "%user% already in Account Subscribers list",
  budgetsFormSubscribeUsers: "Subscribe Users",
  budgetsFormThresholdWarningDaily: "Daily",
  budgetsFormThresholdWarningMonthly: "Monthly",
  budgetsFormTooltipActualThreshold: "Actual thresholds represent points at which you will be alerted for actual usage.",
  budgetsFormTooltipCredits: "Including credits will make your Budget track Net Spend, as opposed to Gross Spend.",
  budgetsFormTooltipExternalUserSubscription: "Subscribe non-Ternary users to Budget alerts.",
  budgetsFormTooltipLatestAmount: "Set latest amount for comparative analysis with actuals or previous budgets.",
  budgetsFormTooltipProjectedThreshold: "Projected thresholds represent points at which you will be alerted based on your projected usage.",
  budgetsFormTooltipReferenceVersion : "Use a saved Reference Version for comparative analysis with actuals or prevoius budgets.",
  budgetsFormTooltipScope: "Define the scope of this Budget with a label such as env:dev.",
  budgetsFormTooltipSpendBasis: "Trigger alert based on actual or projected spend.",
  budgetsFormUsersAdd: "Add",
  budgetsFormUsersAddAll: "Add All",
  budgetsFormUsersAddMany: "Add %count% Users",
  budgetsFormUsersAddOne: "Add 1 User",
  budgetsFormUsersRemoveAll: "Remove All",
  budgetsFormVariableAmount: "Variable",
  budgetsFormVariableMonthsButtonResetMonths: "Reset Months",
  budgetsFormVariableMonthsHighlightedLabelHighlightToggle: "Set as Reference Version",
  budgetsFormVariableMonthsHighlightedToggle: "Set all latest amounts as the Reference Version (as configured below)",
  budgetsFormVariableMonthsHighlightedToggleTooltip: "Set the current configuration as your Reference Version for all months.",
  budgetsFormVariableMonthsTableHeaderHighlighted: "RV Set?",
  budgetsFormVariableMonthsTableHeaderLatest: "Latest Amount",
  budgetsFormVariableMonthsTableHeaderMonth: "Month",
  budgetsFormVariableMonthsTableHeaderTooltipHighlighted: "Shows whether or not there is a Reference Version set for this budget.",
  budgetsFormVariableMonthsTableHeaderVersions: "Versions",
  budgetsHistoricalDailyGraphTitle: "Daily Trends",
  budgetsHistoricalDailyGraphTooltip: "See your daily spend, overlayed over your previous months' spend on the same days to reveal daily trends.",
  budgetsHistoricalMonthlyGraphTitle: "Monthly Trends",
  budgetsHistoricalMonthlyGraphTooltip: "See your total monthly spend over the previous months to reveal monthly trends for this Budget.",
  budgetsHistoryFeedbackSuccess: "Reference Version Updated",
  budgetsHistoryTableHeaderAmount: "Amount",
  budgetsHistoryTableHeaderCreatedAt: "Created At",
  budgetsHistoryTableHeaderCreatedBy: "Created By",
  budgetsHistoryTableHeaderHighlighted: "Reference Version",
  budgetsHistoryTitle: "Versions",
  budgetsReferenceVersionTooltip: "Persist as a Reference Version for comparative analysis against actuals.",
  budgetsTableEmptyPlaceholderText: "Select a Cloud to see the associated Budgets.",
  budgetsTableHeaderActual: "MTD Spend",
  budgetsTableHeaderAmount: "Budget",
  budgetsTableHeaderName: "Name",
  budgetsTableHeaderProjected: "Projected",
  budgetsTableHeaderVariance: "Variance",
  budgetsTableHeaderVariancePercent: "Variance %",
  budgetsTableSourceTooltipGoogle: "Source: Google",
  budgetVersionedMonthlyBarChartActual: "Actual Spend",
  budgetVersionedMonthlyBarChartHighlighted: "Reference Version",
  budgetVersionedMonthlyBarChartLatest: "Latest Version",
  budgetVersionedMonthlyBarChartTitle: "12 Month Budget View",
  budgetTableTitle: "All Budgets",
  deleteBudgetConfirmationMessage: "Are you sure you want to delete this Budget?",
  deleteBudgetConfirmationTitle: "Delete Budget",
  errorCreatingBudgetMessage: "An error occurred while attempting to create the budget. Please try again.",
  errorDeletingBudgetMessage: "An error occurred while attempting to delete the budget. Please try again.",
  errorHighlightingPeriodMessage: "An error occurred while attempting to set the period as the reference version. Please try again.",
  errorUpdatingBudgetMessage: "An error occurred while attempting to update the budget. Please try again.",
  learnMoreCaption: "Learn more about",
  learnMoreLink: "Budgets",
  modalTitleAddToDashboard: "Add to dashboard",
  modalTitleConfirmSaveExistingBudgetToDashboard: "Save %budget% to %dashboard%?",
  na: "n/a",
  statusNotReported: "Not Reported",
  statusPending: "Pending",
  successCreatingBudgetMessage: "Budget successfully created.",
  successDeletingBudgetMessage: "The budget has been successfully deleted.",
  successHighlightingPeriodMessage: "The period has been successfully set as the reference version.",
  successUpdatingBudgetMessage: "Budget successfully updated.",
  title: "Budgets",
  unitMonth: "Month",
  unitPercent: "%",
  unitYear: "Year",
};
