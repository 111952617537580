import { AlertRuleEntity } from "@ternary/api-lib/core/types";
import { CostAlertEntity } from "@ternary/api-lib/core/types/CostAlert";

export type CostAlert = CostAlertEntity & { alertRule?: AlertRuleEntity };

export type CostAlertDimension = { key: string; value: string | null };

export type CostAlertFilters = {
  eventType: string | null;
  sourceAlertRule: string | null;
  alertedAt: string | null;
};

export const CostAlertLegacySourceRule = {
  LEGACY_BIGQUERY_ANOMALY_ML: "LEGACY_BIGQUERY_ANOMALY_ML",
  LEGACY_BILLING_ANOMALY_ML: "LEGACY_BILLING_ANOMALY_ML",
} as const;

export type CostAlertLegacySourceRule =
  (typeof CostAlertLegacySourceRule)[keyof typeof CostAlertLegacySourceRule];
